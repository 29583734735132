import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi, faPrint, faUser, faUserFriends, faCoffee, faVolumeMute} from '@fortawesome/free-solid-svg-icons'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Features and Amenities',
    paragraph: 'Made by students for students, Savant Spaces contains a whole host of features to make our space as accomodating as possible'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id="features"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16" style={{height:'2rem', width:'2rem'}}>
                    <FontAwesomeIcon icon={faWifi} style={{marginTop:'0.5rem'}} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Wi-Fi Access
                    </h4>
                  <p className="m-0 text-sm">
                    In house router with fast speeds that can easily support multiple devices and streaming platforms.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16" style={{height:'2rem', width:'2rem'}}>
                    <FontAwesomeIcon icon={faPrint} style={{marginTop:'0.5rem'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Free Printing
                    </h4>
                  <p className="m-0 text-sm">
                    All registered clients get free printing access for all those last minute papers and review sheets. 
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16" style={{height:'2rem', width:'2rem'}}>
                    <FontAwesomeIcon icon={faUserFriends} style={{marginTop:'0.5rem'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Modern Decor
                    </h4>
                  <p className="m-0 text-sm">
                    Our space is as Instagrammable as it is usable. With 10ft ceilings, modern furniture, and art, you'll have plenty to look at if you doze off.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16" style={{height:'2rem', width:'2rem'}}>
                    <FontAwesomeIcon icon={faUserFriends} style={{marginTop:'0.5rem'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Monthly Subscriptions
                    </h4>
                  <p className="m-0 text-sm">
                      We offer monthly subscriptions, at lower rates, for indivudals that want to spend consistent time on our premises.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16" style={{height:'2rem', width:'2rem'}}>
                    <FontAwesomeIcon icon={faCoffee} style={{marginTop:'0.5rem'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Refreshments
                    </h4>
                  <p className="m-0 text-sm">
                    Free snacks and coffee to keep you energized through your sessions. Kcups <i>are</i> the best study companions after all. 
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16" style={{height:'2rem', width:'2rem'}}>
                    <FontAwesomeIcon icon={faVolumeMute} style={{marginTop:'0.5rem'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Quiet Hours
                    </h4>
                  <p className="m-0 text-sm">
                    So quiet you can hear an AirDrop. No distractions during scheduled quiet hours so you can focus on your most important work.
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;